import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/style.css'
import FunctionalCalendar from 'vue-functional-calendar'
Vue.config.productionTip = false
document.title = '動態知識區塊鏈-Desktop'
Vue.use(FunctionalCalendar, {
  dayNames: ['週一', '週二', '週三', '週四', '週五', '週六', '週日'],
  monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
  shortMonthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
