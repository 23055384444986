<template>
  <div id="app" class="bg-o_pink px-10 pt-10 font-urw_form_family font-normal">
    <Canvas class="">
      <template v-slot:header>
        <header class="flex justify-between items-end p-5 border-b">
          <Logo class="ml-6 mt-6 mb-1" />
          <h1 class="text-3xl text-o_purple-200 font-semibold pr-60 mb-10">動態知識區塊鏈-Desktop</h1>
          <NavBar />
        </header>
      </template>
      <template v-slot:main>
        <main class="px-12 mt-3">
          <router-view class="mb-5"/>
        </main>
      </template>
      <template v-slot:footer>
        <footer class="px-12">
          <Footer/>
        </footer>
      </template>
    </Canvas>
  </div>
</template>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fe917d;
}
//.custom-h-inside {
//  height: 700px;
//}
.content_size {
  height: 60vh;
}
</style>
<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import Canvas from '@/views/Canvas'
import Logo from '@/components/Logo'

export default {
  components: { Logo, Canvas, Footer, NavBar }
}
</script>
