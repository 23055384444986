<template>
  <div class="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-3.5 h-full">
    <div v-for="content in contents" :key="content.h1">
      <h1 class="text-o_purple-200  text-4xl font-semibold my-4">
        {{ content.h1 }}
      </h1>
      <h2 v-if="content.h2" class="font-semibold text-3xl text-left text-o_green px-10 mt-2 border-l-8 border-blue-100"
          v-html="content.h2">
      </h2>
      <p class="text-2xl text-left text-o_purple-100 px-5  mt-8"
         v-html="content.content">
      </p>
    </div>
    <div>
      <h2 class="font-semibold text-3xl text-left text-o_green px-10 mt-2 border-l-8 border-blue-100">
        {{ core_value.h1 }}
      </h2>
      <ul class="flex flex-row w-60 pl-6 mb-6">
        <li class="flex flex-col mt-6 mr-14" v-for="item in core_value.values" :key="item.name">
          <h2 class="text-o_pink text-3xl font-semibold mb-3">{{ item.name }}</h2>
          <h3 class="rounded-full bg-o_purple-200 w-48 h-48 p-11">
            <img :src="item.path" alt="">
          </h3>
          <p class="text-2xl text-o_purple-100">
            {{ item.content }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  data: function () {
    return {
      contents: [
        {
          h1: '什麼是產銷履歷?',
          h2: '農產品產銷履歷制度  <br>' +
            '臺灣良好農業規範實施及驗證＋履歷追溯體系',
          content: '依據「農產品生產及驗證管理法」所推動的自願性農產品產銷履歷制度，即結合上述兩大國際農產品管制制度， ' +
            '同時採取臺灣良好農業規範（Taiwan Good AgriculturePractice，簡稱TGAP）的實施與驗證，以及建立履歷追溯體系。 ' +
            '簡言之，購買使用產銷履歷農產品標章的產銷履歷農產品，不只可以從' +
            '<a target="_blank" class="text-o_pink font-semibold transform hover:underline transition:ease-out duration-300" href="https://taft.coa.gov.tw/default.html">' +
            '「產銷履歷農產品資訊網」</a> ' +
            '查詢到農民的生產紀錄，也代表驗證機構已經為您親赴農民的生產現場，去確認農民所記是否符合所做、' +
            '所做是否符合規範， 並針對產品行抽驗，而每一批產品的相關紀錄也在驗證機構的監控下，嚴格審視，' +
            '一有問題就會馬上處置， 因此可以有效降低履歷資料造假的風險，並且有效管控生產過程不傷害環境、產品不傷害人體。 '
        },
        {
          h1: '什麼是區塊鏈?',
          content: '區塊鏈建立在對等式網路（peer-to-peer，簡稱P2P）上的分散式帳本技術， 參與區塊鏈的用戶可以在自己的電腦上架設一個節點， ' +
            '與其他節點資料同步資料，應用密碼學的加密技術，實現無法被竄改的資料庫。 因為區塊鏈可以詳細記錄每一筆資料，且可以驗證每一筆資料，' +
            '用於農產品的產銷履歷非常適合。 '
        }
      ],
      core_value: {
        h1: '核心價值',
        values: [
          {
            name: '可溯源',
            path: require('../assets/icons/core_img01.svg'),
            content: '區塊鏈詳細記錄每一筆資料，可以作為農產品的溯源資料庫'
          },
          {
            name: '去中心化',
            path: require('../assets/icons/core_img02.svg'),
            content: '傳統的資料庫由單一的伺服器所控制，區塊鏈則由所有參與的節點共同維護'
          },
          {
            name: '無法竄改',
            path: require('../assets/icons/core_img03.svg'),
            content: '透過密碼學的機制，可以驗證資料的正確性'
          },
          {
            name: '資料透明',
            path: require('../assets/icons/core_img04.svg'),
            content: '資料都是透明的，任何人都可以取得區塊鏈上的資料'
          }
        ]

      }
    }
  }
}
</script>

<style scoped>

</style>
